import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['currentCareActions'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SelectBoxWrapper = makeShortcode("SelectBoxWrapper");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "prioritising-self-care"
    }}>{`Prioritising self-care`}</h2>
    <p>{`How are you taking care of yourself at this time? What are your go-to coping strategies when you’re stressed? Click the strategies below that you are most likely to use.`}</p>
    <SelectBoxWrapper id="currentCareActions" description="Click to reveal the most helpful strategies" hiddenContent={<div>
      The strategies highlighted in green have been shown by research to be helpful in protecting our mental health. If
      you aren’t already, try using these strategies when you’re feeling stressed.
      <br />
      <br />
      Those in red are likely to be unhelpful. Although they may be tempting, and might even make you feel better in the
      short-term, try to avoid relying on these strategies in times of stress.
      <br />
      <br />
      <b>We know it can be hard to prioritise your own needs, especially at times like these.</b> It may help to find
      someone to keep you accountable. For example, you could ask a friend to go for walks with you or commit with
      others to reduce reliance on unhelpful strategies (e.g. alcohol free days).
    </div>} items={[{
      id: 'currentCareItem1',
      helpful: true,
      content: 'Exercise or physical activity.'
    }, {
      id: 'currentCareItem2',
      helpful: false,
      content: 'Drink alcohol.'
    }, {
      id: 'currentCareItem3',
      helpful: true,
      content: 'Do something I enjoy.'
    }, {
      id: 'currentCareItem4',
      helpful: false,
      content: 'Use other drugs.'
    }, {
      id: 'currentCareItem5',
      helpful: true,
      content: 'Do something that gives me a sense of achievement.'
    }, {
      id: 'currentCareItem6',
      helpful: false,
      content: 'Stay indoors all day.'
    }, {
      id: 'currentCareItem7',
      helpful: true,
      content: 'Focus on eating a nutritious, balanced diet.'
    }, {
      id: 'currentCareItem8',
      helpful: true,
      content: 'Talk to someone supportive.'
    }, {
      id: 'currentCareItem9',
      helpful: true,
      content: 'Reward myself for achieving something, even a small task/goal.'
    }, {
      id: 'currentCareItem10',
      helpful: false,
      content: 'Avoid seeing or talking to other people.'
    }, {
      id: 'currentCareItem11',
      helpful: false,
      content: 'Avoid or procrastinate on responsibilities.'
    }, {
      id: 'currentCareItem12',
      helpful: true,
      content: 'Use relaxation techniques (e.g. meditation, progressive muscle relaxation training).'
    }, {
      id: 'currentCareItem13',
      helpful: true,
      content: 'Use strategies that have worked for me in past stressful situations.'
    }, {
      id: 'currentCareItem14',
      helpful: true,
      content: 'Focus on getting enough sleep each night.'
    }, {
      id: 'currentCareItem15',
      helpful: true,
      content: 'Ask a trusted friend or relative to do something together.'
    }, {
      id: 'currentCareItem16',
      helpful: true,
      content: 'Reach out to people around you to let them know how you’re feeling.'
    }, {
      id: 'currentCareItem17',
      helpful: false,
      content: 'Mull over how bad I’m feeling.'
    }]} mdxType="SelectBoxWrapper" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      